import React, { useState } from 'react';
import {
  Container,
  Grid,
  Box,
  Typography,
  IconButton,
  Button,
  TextField,
} from '@mui/material';
import TwitterIcon from '@mui/icons-material/Twitter';
// import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';

const Footer = ({ handleScheduleConsultation }) => {
  const [formData, setFormData] = useState({ name: '', email: '', message: '' });
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('https://formspree.io/f/xjkvvkkz', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setFormSubmitted(true);
      } else {
        setErrorMessage('Something went wrong. Please try again.');
      }
    } catch (error) {
      setErrorMessage('There was an error submitting the form.');
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <Box
    id="footer"
      sx={{
        backgroundColor: '#1a1a1a',
        color: '#E0FFFD',
        py: 8,
        mt: 8,
        borderTop: '1px solid rgba(255, 255, 255, 0.2)',
      }}
    >
      <Container>
        {/* AI Solutions Section */}
        {/* <Grid item xs={12}>
          <Box
            sx={{
              backgroundColor: '#2C2C2C',
              p: 5,
              borderRadius: 2,
              boxShadow: '0px 0px 15px 2px rgba(255, 0, 127, 0.6)',
              mb: 5,
            }}
          >
            <Grid container justifyContent="center" alignItems="center" spacing={3}>
              <Grid item xs={12} md={3}>
                <Box
                  component="img"
                  src="/images/hai.png"
                  alt="AI Solutions"
                  sx={{ width: '100%', height: 'auto', borderRadius: 1 }}
                />
              </Grid>
              <Grid item xs={12} md={9}>
                <Typography
                  variant="h4"
                  gutterBottom
                  sx={{ color: '#FF007F', fontWeight: 'bold' }}
                >
                  AI Solutions for Every Business
                </Typography>
                <Typography variant="body1" paragraph>
                  Our AI models are designed to help businesses automate, optimize, and
                  innovate. Whether through LLMs or image-based AI models, we provide
                  powerful solutions for various industries.
                </Typography>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: '#FF007F',
                    '&:hover': {
                      backgroundColor: '#FF3399',
                    },
                    mt: 2,
                  }}
                  onClick={handleScheduleConsultation}
                >
                  Schedule a Consultation
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Grid> */}

        {/* Contact Form for Lead Generation */}
        <Box
          sx={{
            backgroundColor: '#2C2C2C',
            p: 5,
            width: '80%',
            height: 'auto',
            maxWidth: '600px',
            margin: '0 auto',
            borderRadius: 2,
            boxShadow: '0px 0px 15px 2px rgba(255, 0, 127, 0.6)',
          }}
        >
          <Typography
            variant="h4"
            sx={{ textAlign: 'center', color: '#FF007F', fontWeight: 'bold', mb: 3 }}
          >
            Get in Touch for a Custom AI Solution
          </Typography>
          {formSubmitted ? (
            <Typography variant="body1" sx={{ color: '#E0FFFD', textAlign: 'center' }}>
              Thank you for reaching out! We will get back to you shortly.
            </Typography>
          ) : (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3} justifyContent="center">
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Name"
                    name="name"
                    variant="filled"
                    value={formData.name}
                    onChange={handleChange}
                    InputLabelProps={{ style: { color: '#E0FFFD' } }}
                    InputProps={{ style: { backgroundColor: '#333', color: '#FFF', borderRadius: '5px' } }}
                    sx={{ mb: 2 }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Email"
                    name="email"
                    variant="filled"
                    value={formData.email}
                    onChange={handleChange}
                    InputLabelProps={{ style: { color: '#E0FFFD' } }}
                    InputProps={{ style: { backgroundColor: '#333', color: '#FFF', borderRadius: '5px' } }}
                    sx={{ mb: 2 }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Message"
                    name="message"
                    variant="filled"
                    value={formData.message}
                    onChange={handleChange}
                    multiline
                    rows={6}
                    InputLabelProps={{ style: { color: '#E0FFFD' } }}
                    InputProps={{ style: { backgroundColor: '#333', color: '#FFF', borderRadius: '5px' } }}
                  />
                </Grid>
                <Grid item xs={12} sx={{ textAlign: 'center', mt: 3 }}>
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{
                      backgroundColor: '#FF007F',
                      '&:hover': {
                        backgroundColor: '#FF3399',
                      },
                      width: '200px',
                      height: '50px',
                      fontSize: '18px',
                      fontWeight: 'bold',
                    }}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </form>
          )}
          {errorMessage && (
            <Typography variant="body2" color="error" textAlign="center" mt={2}>
              {errorMessage}
            </Typography>
          )}
        </Box>

        {/* Close Button */}
        <Box textAlign="center" mt={4}>
          <IconButton
            onClick={handleBack}
            sx={{
              color: '#FF007F',
              backgroundColor: '#1a1a1a',
              border: '2px solid #FF007F',
              borderRadius: '50%',
              padding: '10px',
              transition: 'transform 0.3s, box-shadow 0.3s',
              '&:hover': {
                transform: 'scale(1.1)',
                boxShadow: '0 4px 20px rgba(255, 0, 127, 0.6)',
              },
            }}
            aria-label="Close"
          >
            <CloseIcon fontSize="large" />
          </IconButton>
        </Box>

        {/* Social Media Icons */}
        <Box sx={{ textAlign: 'center', mt: 5 }}>
          <Typography variant="h6" sx={{ color: '#E0FFFD', mb: 2 }}>
            Follow Us
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              gap: '10px',
              mb: 3,
            }}
          >
            {[
              { href: 'https://twitter.com/hive_ai_labs', label: 'Twitter', icon: <TwitterIcon fontSize="medium" sx={{ color: '#1DA1F2' }} /> },
              { href: 'https://linkedin.com/company/hive-ai-tech', label: 'LinkedIn', icon: <LinkedInIcon fontSize="medium" sx={{ color: '#0A66C2' }} /> },
            ].map((social, index) => (
              <IconButton key={index} component="a" href={social.href} target="_blank" aria-label={social.label}>
                {social.icon}
              </IconButton>
            ))}
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
