// HeroSection.jsx


import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../App.css';
// import ChatWidget from './ChatWidget'; 
import ParticleBackground from './ParticleBackground'; // Import the ParticleBackground component

const HeroSection = () => {
  const navigate = useNavigate(); // Using useNavigate for navigation

  const handleClick = () => {
    navigate('/about'); // Navigate to the About page
  };

  const handleWorkClick = () => {
    navigate('/work'); // Navigate to the Portfolio page
  };
  const handlePricingClick = () => {
    navigate('/get-started'); // Navigate to the Pricing page
  };

  return (
    <section id="hero" className="hero dark sphere">
      <ParticleBackground /> {/* Add the ParticleBackground component here */}
      {/* Adding the grain effect */}
      <div className="grain"></div> {/* This is the grain overlay */}
      <div className="hero__content">
        <p>Where Technology Meets</p>
        <h1>Imagination</h1>
      </div>
      <button onClick={handleClick} className="hero__trigger-button top">
        About
      </button>
      <button onClick={handleWorkClick} className="hero__trigger-button bottom">
        Portfolio
      </button>
      <button onClick={handlePricingClick} className="hero__right-button">
        Get Started
      </button>
      <img
        className="logo"
        src="images/logo_old.png"
        width="70"
        height="75"
        alt="Hive AI Logo"
      />
      <div className="meta sound">
        <a className="mute-action muted" href="#"></a>
      </div>
      {/* <ChatWidget />  */}
    </section>
  );
};

export default HeroSection;
