// AboutSection.jsx

import React from 'react';
import { Box, IconButton } from '@mui/material';
// import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import CaseStudySection from './CaseStudySection';  // Import Case Study Section
import Footer from './Footer';  // Import Footer component
import FullStackDevSection from './FullStackDevSection';

const AboutSection = () => {
  const navigate = useNavigate();

  const handleClose = () => {
    navigate('/'); // Navigate back to the home page
  };

  const handleScheduleConsultation = () => {
    // Navigate to external consultation scheduling link
    window.location.href = 'https://hivetechnology.setmore.com/services/f3a9069c-f508-4431-8863-0452bc6d62ef?source=easyshare';
  };

  return (
    <Box
      id="about"
      sx={{
        position: 'absolute',
        zIndex: 2,
        width: '100%',
        height: '100%',
        backgroundColor: '#1a1a1a', // Matte black background
        color: '#E0FFFD', // Neon cyan text for contrast
        overflowY: 'scroll',
        top: 0,
        pointerEvents: 'all',
        paddingTop: '64px',
        backdropFilter: 'blur(10px)', // Adding some blur for depth
      }}
    >
     

      <CaseStudySection />

      <FullStackDevSection />

      {/* Footer is now placed at the bottom */}
      <Footer handleScheduleConsultation={handleScheduleConsultation} />
    </Box>
  );
};

export default AboutSection;
