import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import MetaTags from './components/MetaTags';
import HeroSection from './components/HeroSection';
import AboutSection from './components/AboutSection';
import WorkSection from './components/WorkSection';
import PricingSection from './components/PricingSection';
import ContractPage from './components/ContractPage';
import GetStarted from './components/GetStarted';
import ThankYouScreen from './components/ThankYouScreen';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Box } from '@mui/material'; // Import Box for layout

const theme = createTheme();  // Create a theme

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Box>
          <MetaTags />
          <Routes>
            <Route path="/" element={<HeroSection />} />
            <Route path="/about" element={<AboutSection />} />
            <Route path="/work" element={<WorkSection />} />
            <Route path="/pricing" element={<PricingSection />} />
            <Route path="/contract" element={<ContractPage />} />
            <Route path="/get-started" element={<GetStarted />} />
            <Route path="/thank-you" element={<ThankYouScreen />} />
            {/* Add more routes here if needed */}
          </Routes>
        </Box>
      </Router>
    </ThemeProvider>
  );
}

export default App;





// import React from 'react';
// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import MetaTags from './components/MetaTags';
// import HeroSection from './components/HeroSection';
// import AboutSection from './components/AboutSection';
// import WorkSection from './components/WorkSection';
// import './App.css';

// function App() {
//   return (
//     <Router>
//       <div className="App">
//         <MetaTags />
//         <Routes>
//           <Route path="/" element={<HeroSection />} />
//           <Route path="/about" element={<AboutSection />} />
//           <Route path="/work" element={<WorkSection />} />
//           {/* Add more routes here if needed */}
//         </Routes>
//       </div>
//     </Router>
//   );
// }

// export default App;

