import React from 'react';
import { Container, Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';


const ThankYouScreen = () => {
  const navigate = useNavigate();

  const handleGoHome = () => {
    navigate('/'); // Navigate to homepage or another appropriate page
  };

  return (
    <Box
      sx={{
        width: '100%',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'linear-gradient(135deg, #1a1a1a 30%, #333333 100%)',
        color: '#E0FFFD',
      }}
    >
      <Container maxWidth="sm" sx={{ textAlign: 'center' }}>
        <Typography
          variant="h3"
          sx={{ fontWeight: 'bold', mb: 3, color: '#FF007F' }}
        >
          Thank You!
        </Typography>
        <Typography variant="h6" sx={{ mb: 3, color: '#E0FFFD' }}>
          A representative will reach out within 24 hours to go over your next steps.
        </Typography>
        <Button
          variant="contained"
          sx={{
            mt: 3,
            backgroundColor: '#FF007F',
            color: '#fff',
            '&:hover': {
              backgroundColor: '#FF4E8A',
            },
          }}
          onClick={handleGoHome}
        >
          Go Back to Homepage
        </Button>
      </Container>
    </Box>
  );
};

export default ThankYouScreen;
