import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';
import '../App.css'; // Ensure this path is correct or adjust as needed

const ParticleBackground = () => {
  const containerRef = useRef();

  useEffect(() => {
    let camera, scene, renderer;
    let count = 0;
    const SEPARATION = 100,
      AMOUNTX = 50,
      AMOUNTY = 50;
    let mouseX = 0,
      mouseY = 0;
    let windowHalfX = window.innerWidth / 2;
    let windowHalfY = window.innerHeight / 2;

    const particles = [];

    // Initialize Three.js Scene
    const init = () => {
      const container = containerRef.current;

      // Camera Setup
      camera = new THREE.PerspectiveCamera(
        75,
        window.innerWidth / window.innerHeight,
        1,
        10000
      );
      camera.position.z = 1000;

      // Scene Setup
      scene = new THREE.Scene();

      // Particle Grid Background
      const particleMaterial = new THREE.PointsMaterial({
        color: 0x00CFFF, // Neon Blue
        size: 3,
      });

      const particleGeometry = new THREE.BufferGeometry();
      const positions = new Float32Array(AMOUNTX * AMOUNTY * 3);

      let i = 0;
      for (let ix = 0; ix < AMOUNTX; ix++) {
        for (let iy = 0; iy < AMOUNTY; iy++) {
          positions[i] = ix * SEPARATION - (AMOUNTX * SEPARATION) / 2;
          positions[i + 1] = 0;
          positions[i + 2] = iy * SEPARATION - (AMOUNTY * SEPARATION) / 2;
          i += 3;
        }
      }

      particleGeometry.setAttribute(
        'position',
        new THREE.Float32BufferAttribute(positions, 3)
      );

      const points = new THREE.Points(particleGeometry, particleMaterial);
      scene.add(points);

      // Adding Sprites with Color Variation (Cyan, Pink, and Purple)
      const colors = [0x00FFEC, 0xFF007A, 0x8B00FF]; // Cyan, Pink, Purple

      colors.forEach((color) => {
        const spriteMaterial = new THREE.SpriteMaterial({
          color: color,
        //   map: new THREE.TextureLoader().load(process.env.PUBLIC_URL + '/images/hai.png'),
          blending: THREE.AdditiveBlending,
        });

        for (let i = 0; i < 500; i++) {
          const particle = new THREE.Sprite(spriteMaterial);
          particle.position.x = Math.random() * 2 - 1;
          particle.position.y = Math.random() * 2 - 1;
          particle.position.z = Math.random() * 2 - 1;
          particle.position.normalize();
          particle.position.multiplyScalar(Math.random() * 10 + 450);
          particle.scale.multiplyScalar(2);
          scene.add(particle);
        }
      });

      // Adding Lines with Neon Colors
      for (let i = 0; i < 300; i++) {
        const geometry = new THREE.BufferGeometry();
        const vertices = new Float32Array(6);

        const vertex = new THREE.Vector3(
          Math.random() * 2 - 1,
          Math.random() * 2 - 1,
          Math.random() * 2 - 1
        );
        vertex.normalize();
        vertex.multiplyScalar(450);

        vertices[0] = vertex.x;
        vertices[1] = vertex.y;
        vertices[2] = vertex.z;

        const vertex2 = vertex.clone();
        vertex2.multiplyScalar(Math.random() * 0.3 + 1);

        vertices[3] = vertex2.x;
        vertices[4] = vertex2.y;
        vertices[5] = vertex2.z;

        geometry.setAttribute(
          'position',
          new THREE.Float32BufferAttribute(vertices, 3)
        );

        const lineMaterial = new THREE.LineBasicMaterial({
          color: colors[Math.floor(Math.random() * colors.length)],
          opacity: Math.random(),
          transparent: true,
        });

        const line = new THREE.Line(geometry, lineMaterial);
        scene.add(line);
      }

      // Renderer Setup
      renderer = new THREE.WebGLRenderer({ alpha: true });
      renderer.setPixelRatio(window.devicePixelRatio);
      renderer.setSize(window.innerWidth, window.innerHeight);
      container.appendChild(renderer.domElement);

      // Event Listeners
      document.addEventListener('mousemove', onDocumentMouseMove, false);
      window.addEventListener('resize', onWindowResize, false);

      animate(points, particleGeometry);
    };

    // Handle Window Resize
    const onWindowResize = () => {
      windowHalfX = window.innerWidth / 2;
      windowHalfY = window.innerHeight / 2;

      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();

      renderer.setSize(window.innerWidth, window.innerHeight);
    };

    // Handle Mouse Movement
    const onDocumentMouseMove = (event) => {
      mouseX = event.clientX - windowHalfX;
      mouseY = event.clientY - windowHalfY;
    };

    // Animation Loop
    const animate = (points, geometry) => {
      requestAnimationFrame(() => animate(points, geometry));
      render(points, geometry);
    };

    // Render Scene
    const render = (points, geometry) => {
      camera.position.x += (mouseX - camera.position.x) * 0.05;
      camera.position.y += (-mouseY + 200 - camera.position.y) * 0.05;
      camera.lookAt(scene.position);

      const positions = geometry.attributes.position.array;
      let i = 0;

      for (let ix = 0; ix < AMOUNTX; ix++) {
        for (let iy = 0; iy < AMOUNTY; iy++) {
          positions[i + 1] =
            Math.sin((ix + count) * 0.3) * 50 +
            Math.sin((iy + count) * 0.5) * 50;
          i += 3;
        }
      }

      geometry.attributes.position.needsUpdate = true;

      renderer.setClearColor(0x000000, 0);
      renderer.render(scene, camera);

      count += 0.1;
    };

    init();

    // Cleanup on Unmount
    return () => {
      const container = containerRef.current;
      if (container) {
        container.removeChild(renderer.domElement);
      }
      document.removeEventListener('mousemove', onDocumentMouseMove, false);
      window.removeEventListener('resize', onWindowResize, false);
      if (renderer) {
        renderer.dispose();
      }
    };
  }, []);

  return <div className="hero__three-container" ref={containerRef}></div>;
};

export default ParticleBackground;

