// WorkSection.jsx

import React from 'react';
import {
  Container,
  Grid,
  Box,
  Typography,
  Paper,
  Divider,
  Button,
} from '@mui/material';


import { Bar, Pie } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import Footer from './Footer';

 

// Register Chart.js components
ChartJS.register(CategoryScale, LinearScale, BarElement, ArcElement, Title, Tooltip, Legend);

// Project Data
const projects = [
  {
    title: 'Legal Services Automation',
    description:
      'Integrated AI and large language models (LLMs) using Node.js, Python, and TensorFlow to automate legal document generation, reducing operational overhead by 50% and increasing case capacity by 40%.',
    icon: '📄',
  },
  {
    title: 'LLM-Based Customer Support Chatbots',
    description:
      'Developed intelligent customer support chatbots with GPT-3 and GPT-4 integrated with Node.js, reducing response times by 80% and enhancing customer satisfaction.',
    icon: '🤖',
  },
  {
    title: 'AI-Powered E-Commerce',
    description:
      'Implemented AI chatbots for customer support and TensorFlow-based models for demand forecasting, optimizing inventory management and boosting operational efficiency by 15%.',
    icon: '🛒',
  },
  {
    title: 'AI in Healthcare',
    description:
      'Built AI diagnostic tools using TensorFlow and PyTorch, along with a virtual assistant for scheduling, resulting in a 20% reduction in administrative costs and improved patient care quality.',
    icon: '🏥',
  },
  {
    title: 'Custom Smart APIs for Information Monitoring',
    description:
      'Developed custom APIs using Pinecone and TensorFlow to monitor and analyze large datasets in real-time, enabling businesses to gain actionable insights and improve decision-making.',
    icon: '🔗',
  },
];

// Chart Data
const barData = {
  labels: ['Project Efficiency', 'Cost Reduction', 'Scalability'],
  datasets: [
    {
      label: 'Performance (%)',
      data: [90, 80, 85],
      backgroundColor: '#FF007F', // Neon pink bars
    },
  ],
};

const pieData = {
  labels: ['React', 'Node.js', 'Python', 'TensorFlow', 'Pinecone'],
  datasets: [
    {
      label: 'Technology Usage',
      data: [35, 25, 20, 10, 10],
      backgroundColor: [
        '#FF007F',
        '#00FFC6',
        '#E0FFFD',
        '#00BFFF',
        '#FF6347',
      ], // Different neon colors
    },
  ],
};

const chartOptions = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
      labels: {
        color: '#E0FFFD', // Neon cyan text for legend
      },
    },
    title: {
      display: true,
      text: 'Full-Stack Development Impact',
      color: '#E0FFFD', // Neon cyan text for title
    },
  },
  scales: {
    x: {
      ticks: {
        color: '#E0FFFD',
      },
    },
    y: {
      ticks: {
        color: '#E0FFFD',
      },
      grid: {
        color: 'rgba(224, 255, 253, 0.2)', // Light cyan grid lines
      },
    },
  },
};

// Motion Variants
const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2,
    },
  },
};

const cardVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0 },
};

  // Smooth scroll to the footer
  const scrollToFooter = () => {
    const footerElement = document.getElementById('footer');
    if (footerElement) {
      footerElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

// Reusable Project Card Component
const ProjectCard = ({ project }) => (
  <motion.div variants={cardVariants}>
    <Paper
      elevation={3}
      sx={{
        padding: 3,
        borderRadius: 2,
        backgroundColor: '#2c2c2c',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        transition: 'transform 0.3s',
        '&:hover': {
          transform: 'translateY(-10px)',
          boxShadow: '0 8px 20px rgba(255, 0, 127, 0.4)',
        },
      }}
      aria-label={project.title}
    >
      <Box textAlign="center" mb={2}>
        <Typography variant="h2">{project.icon}</Typography>
      </Box>
      <Typography
        variant="h5"
        sx={{ color: '#FF007F', fontWeight: 'bold', mb: 2 }}
        align="center"
      >
        {project.title}
      </Typography>
      <Typography variant="body1" color="#E0FFFD" align="center">
        {project.description}
      </Typography>
    </Paper>
  </motion.div>
);

// Reusable Testimonial Card Component
const TestimonialCard = ({ testimonial }) => (
  <Paper
    sx={{
      padding: 3,
      borderRadius: 2,
      backgroundColor: '#2c2c2c',
      height: '100%',
      boxShadow: '0 4px 10px rgba(0,0,0,0.3)',
    }}
    aria-label={testimonial.author}
  >
    <Typography variant="body1" color="#E0FFFD" gutterBottom>
      "{testimonial.quote}"
    </Typography>
    <Typography variant="subtitle1" sx={{ color: '#FF007F', fontWeight: 'bold' }}>
      - {testimonial.author}, {testimonial.position}
    </Typography>
  </Paper>
);

// Testimonials Data
const testimonials = [
  {
    quote: 'Hive AI Tech transformed our legal processes, saving us time and resources.',
    author: 'Jane Martinez',
    position: 'Legal Advisor',
  },
  {
    quote: 'The AI solutions provided by Hive AI Tech have significantly improved our customer support.',
    author: 'John Hopkins',
    position: 'Customer Support Manager',
  },
  {
    quote: 'Their expertise in AI and full-stack development is unparalleled.',
    author: 'Emily Johnson',
    position: 'CTO',
  },
];

const handleScheduleConsultation = () => {
  // Navigate to external consultation scheduling link
  window.location.href = 'https://hivetechnology.setmore.com/services/f3a9069c-f508-4431-8863-0452bc6d62ef?source=easyshare';
};


const WorkSection = () => {
  const navigate = useNavigate();


  return (
    <Box
      id="work"
      sx={{
        width: '100%',
        height: '100vh', // Ensure the section takes up the full viewport height
        overflowY: 'auto', // Enable vertical scrolling
        backgroundColor: '#1a1a1a', // Matte black background
        paddingY: { xs: '3rem', md: '5rem' },
        color: '#E0FFFD', // Neon cyan text color
      }}
    >
      <Container>
        {/* Section Intro */}
        <motion.div
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <Grid container justifyContent="center">
            <Grid item xs={12} md={8}>
              <Box mb={5} textAlign="center">
                <Typography
                  variant="h3"
                  gutterBottom
                  sx={{ color: '#FF007F', fontWeight: 'bold' }}
                >
                  Our Full-Stack Development Journey
                </Typography>
                <Typography variant="h6" color="#E0FFFD">
                  Dive into our portfolio to discover how we've leveraged cutting-edge
                  technologies like React, Node.js, Python, TensorFlow, Pinecone, and
                  GPT-4 to create scalable and high-performing solutions across various
                  industries.
                </Typography>
                <Box mt={4}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={scrollToFooter}
                    sx={{
                      backgroundColor: '#FF007F',
                      '&:hover': { backgroundColor: '#e60073' },
                    }}
                  >
                    Get in Touch
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </motion.div>

        {/* Projects Section */}
        <Box mt={8}>
          <Typography
            variant="h4"
            align="center"
            gutterBottom
            sx={{ color: '#FF007F', fontWeight: 'bold' }}
          >
            Featured Projects
          </Typography>
          <motion.div
            variants={containerVariants}
            initial="hidden"
            animate="visible"
          >
            <Grid container spacing={4} justifyContent="center">
              {projects.map((project, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <ProjectCard project={project} />
                </Grid>
              ))}
            </Grid>
          </motion.div>
        </Box>

        {/* Charts Section */}
        <Box mt={10}>
          <Typography
            variant="h4"
            align="center"
            gutterBottom
            sx={{ color: '#FF007F', fontWeight: 'bold' }}
          >
            Our Impact
          </Typography>
          <Grid container spacing={4} justifyContent="center">
            {/* Performance Metrics */}
            <Grid item xs={12} md={6}>
              <motion.div
                whileHover={{ scale: 1.05 }}
                transition={{ duration: 0.3 }}
              >
                <Paper
                  sx={{
                    padding: 4,
                    borderRadius: 2,
                    backgroundColor: '#2c2c2c',
                    boxShadow: '0 4px 10px rgba(0,0,0,0.3)',
                  }}
                >
                  <Typography
                    variant="h5"
                    sx={{ color: '#FF007F', fontWeight: 'bold', mb: 3 }}
                    align="center"
                  >
                    Project Performance Metrics
                  </Typography>
                  <Bar data={barData} options={chartOptions} />
                </Paper>
              </motion.div>
            </Grid>

            {/* Technology Usage */}
            <Grid item xs={12} md={6}>
              <motion.div
                whileHover={{ scale: 1.05 }}
                transition={{ duration: 0.3 }}
              >
                <Paper
                  sx={{
                    padding: 4,
                    borderRadius: 2,
                    backgroundColor: '#2c2c2c',
                    boxShadow: '0 4px 10px rgba(0,0,0,0.3)',
                  }}
                >
                  <Typography
                    variant="h5"
                    sx={{ color: '#FF007F', fontWeight: 'bold', mb: 3 }}
                    align="center"
                  >
                    Technology Stack Distribution
                  </Typography>
                  <Pie data={pieData} options={chartOptions} />
                </Paper>
              </motion.div>
            </Grid>
          </Grid>
        </Box>

        {/* Testimonials Section */}
        <Box mt={10}>
          <Typography
            variant="h4"
            align="center"
            gutterBottom
            sx={{ color: '#FF007F', fontWeight: 'bold' }}
          >
            What Our Clients Say
          </Typography>
          <Grid container spacing={4} justifyContent="center">
            {testimonials.map((testimonial, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <TestimonialCard testimonial={testimonial} />
              </Grid>
            ))}
          </Grid>
        </Box>

        <Divider sx={{ mt: 8, mb: 5, backgroundColor: '#FF007F' }} />
        
        <Footer handleScheduleConsultation={handleScheduleConsultation} />
      </Container>
    </Box>
  );
};

export default WorkSection;
