// firebase.js
import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';

const firebaseConfig = {
  apiKey: "AIzaSyCEJpMVAuxbNHGv5lvhIWZQOueJXgwBJa8",
  authDomain: "hive-labs-b2094.firebaseapp.com",
  projectId: "hive-labs-b2094",
  storageBucket: "hive-labs-b2094.appspot.com",
  messagingSenderId: "807661651580",
  appId: "1:807661651580:web:ab83624ed5176c9c192bf9",
  measurementId: "G-8SWT5MB03M",
  databaseURL: "https://hive-labs-b2094-default-rtdb.firebaseio.com/",
};


const app = initializeApp(firebaseConfig);
const db = getDatabase(app);

export { db };
