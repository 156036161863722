import React from 'react';
import {
  Container,
  Grid,
  Box,
  Typography,
  Paper,
  Button,
  Divider,
} from '@mui/material';
import { motion } from 'framer-motion';
import Footer from './Footer';

const GetStarted = () => {
  return (
    <Box
      id="getstarted"
      sx={{
        position: 'absolute',
        zIndex: 2,
        width: '100%',
        height: '100%',
        backgroundColor: '#1a1a1a', // Matte black background
        color: '#E0FFFD', // Neon cyan text for contrast
        overflowY: 'scroll',
        top: 0,
        pointerEvents: 'all',
        paddingTop: '64px',
        backdropFilter: 'blur(10px)', // Adding some blur
      }}
    >
      <Container maxWidth="md">
        {/* Introduction Section */}
        <motion.div
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <Box mb={5} textAlign="center">
                <Typography
                  variant="h3"
                  gutterBottom
                  sx={{
                    color: '#FF007F',
                    fontWeight: 'bold',
                    letterSpacing: '0.1rem',
                  }}
                >
                  Get Started with Hive Labs
                </Typography>
                <Typography variant="h6" color="#E0FFFD">
                  We’re excited to help your business grow with our AI, web, and
                  blockchain solutions. Our streamlined process ensures that you
                  get the best results, fast.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </motion.div>

        {/* Process Steps */}
        <Box mt={4}>
          <Paper
            elevation={5}
            sx={{
              padding: 4,
              borderRadius: 3,
              backgroundColor: '#444',
              color: '#fff',
              boxShadow: '0 10px 30px rgba(0, 0, 0, 0.2)',
            }}
          >
            <Typography
              variant="h5"
              align="center"
              sx={{
                color: '#FF007F',
                fontWeight: 'bold',
                marginBottom: '2rem',
              }}
            >
              Our Process
            </Typography>

            <Grid container spacing={4}>
              <Grid item xs={12} sm={6}>
                <Typography
                  variant="h6"
                  sx={{ color: '#00FFC6', fontWeight: 'bold', mb: 2 }}
                >
                  1. Initial Consultation
                </Typography>
                <Typography variant="body1">
                  We’ll meet with you to understand your business needs, project
                  goals, and how our AI, web, or blockchain solutions can
                  benefit your company.
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography
                  variant="h6"
                  sx={{ color: '#00FFC6', fontWeight: 'bold', mb: 2 }}
                >
                  2. Custom Proposal
                </Typography>
                <Typography variant="body1">
                  Based on our consultation, we’ll create a customized proposal
                  detailing the services, timeline, and cost to achieve your
                  desired outcomes.
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography
                  variant="h6"
                  sx={{ color: '#00FFC6', fontWeight: 'bold', mb: 2 }}
                >
                  3. Development Phase
                </Typography>
                <Typography variant="body1">
                  Our team will start building your solution, providing regular
                  updates and working with you to ensure we meet your
                  expectations.
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography
                  variant="h6"
                  sx={{ color: '#00FFC6', fontWeight: 'bold', mb: 2 }}
                >
                  4. Testing & Feedback
                </Typography>
                <Typography variant="body1">
                  Once development is complete, we thoroughly test the solution.
                  You’ll review it, provide feedback, and we’ll make necessary
                  revisions.
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography
                  variant="h6"
                  sx={{ color: '#00FFC6', fontWeight: 'bold', mb: 2 }}
                >
                  5. Deployment & Launch
                </Typography>
                <Typography variant="body1">
                  After testing and approval, we launch your solution and ensure
                  it’s running smoothly.
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography
                  variant="h6"
                  sx={{ color: '#00FFC6', fontWeight: 'bold', mb: 2 }}
                >
                  6. Ongoing Support
                </Typography>
                <Typography variant="body1">
                  Post-launch, we offer ongoing support, maintenance, and
                  additional services like web hosting and AI model updates.
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Box>

        {/* Get Started Button */}
        <Box mt={8} textAlign="center">
          <Button
            variant="contained"
            sx={{
              backgroundColor: '#FF007F',
              color: '#fff',
              padding: '1rem 2rem',
              fontSize: '1.2rem',
              fontWeight: 'bold',
              '&:hover': { backgroundColor: '#FF4E8A' },
            }}
            onClick={() => {
              // Action to start process (e.g., route to contact page or pricing)
              window.location.href = '/contract'; // Change to your intended route
            }}
          >
            Get Started
          </Button>
        </Box>

        <Divider sx={{ mt: 8, mb: 5, backgroundColor: '#FF007F' }} />
      </Container>

      {/* Footer */}
      <Footer />
    </Box>
  );
};

export default GetStarted;
