import React from 'react';
import { Container, Grid, Typography, Box, Button } from '@mui/material';
import { Bar, Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, ArcElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, ArcElement, Title, Tooltip, Legend);

const CaseStudySection = () => {
  // Data for bar chart (AI Benefits)
  const chartData = {
    labels: ['Customer Satisfaction', 'Response Time Reduction', 'Cost Savings'],
    datasets: [
      {
        label: 'Improvement (%)',
        data: [30, 85, 40],
        backgroundColor: '#FF007F', // Neon pink color
      },
    ],
  };

  // Data for doughnut chart (AI Solutions Impact)
  const doughnutData = {
    labels: ['LLMs & Augmented Retrieval', 'Image Classification', 'Workflow Automation'],
    datasets: [
      {
        label: 'AI Solutions Impact',
        data: [50, 30, 20],
        backgroundColor: ['#FF007F', '#E0FFFD', '#00FFC6'], // Neon pink, cyan, green
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
        labels: {
          color: '#E0FFFD', // Neon cyan text for legend
        },
      },
      title: {
        display: true,
        text: 'Impact of Hive Labs AI Solutions',
        color: '#E0FFFD', // Neon cyan text for title
        font: {
          size: 20,
        },
      },
    },
    scales: {
      x: {
        ticks: {
          color: '#E0FFFD', // Neon cyan for x-axis
        },
      },
      y: {
        ticks: {
          color: '#E0FFFD', // Neon cyan for y-axis
        },
        grid: {
          color: 'rgba(224, 255, 253, 0.2)', // Light cyan grid
        },
      },
    },
  };

  const handleScheduleConsultation = () => {
    // Navigate to external consultation scheduling link
    window.location.href = 'https://hivetechnology.setmore.com/services/f3a9069c-f508-4431-8863-0452bc6d62ef?source=easyshare';
  };
  

  return (
    <Box
      sx={{
        backgroundColor: "#1a1a1a",
        py: 8,
        px: 3,
        color: "#E0FFFD", // Neon cyan text
        textAlign: "center",
      }}
    >
      <Container>
        <Grid item xs={12}>
          <Box
            sx={{
              backgroundColor: "#2C2C2C",
              p: 5,
              borderRadius: 2,
              boxShadow: "0px 0px 15px 2px rgba(255, 0, 127, 0.6)",
              mb: 5,
            }}
          >
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              spacing={3}
            >
              <Grid item xs={12} md={3}>
                <Box
                  component="img"
                  src="/images/hai.png"
                  alt="AI Solutions"
                  sx={{ width: "100%", height: "auto", borderRadius: 1 }}
                />
              </Grid>
              <Grid item xs={12} md={9}>
                <Typography
                  variant="h4"
                  gutterBottom
                  sx={{ color: "#FF007F", fontWeight: "bold" }}
                >
                  AI Solutions Tailored for Your Business
                </Typography>
                <Typography variant="body1" paragraph>
                  Discover how our AI models can transform your business by
                  automating tasks, optimizing operations, and driving
                  innovation. From LLMs to image-based AI models, we offer
                  custom solutions across various industries.
                </Typography>
                <Typography variant="body1" paragraph>
                  Schedule a meeting with us today to explore how we can help
                  your business reach new heights with AI-powered technology.
                </Typography>

                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#FF007F",
                    "&:hover": {
                      backgroundColor: "#FF3399",
                    },
                    mt: 2,
                  }}
                  onClick={handleScheduleConsultation}
                >
                  Schedule a meeting
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Typography
          variant="h3"
          sx={{ color: "#FF007F", fontWeight: "bold", mb: 3 }}
        >
          AI-Powered Transformation Across Industries
        </Typography>
        <Typography variant="body1" paragraph sx={{ mb: 5 }}>
          At Hive Labs, we specialize in AI solutions that are transforming
          businesses across multiple sectors. Whether it’s leveraging large
          language models (LLMs), augmented retrieval systems, or image
          classification models, we help companies achieve cost savings,
          enhanced customer satisfaction, and scalable growth.
        </Typography>

        {/* Charts Section */}
        <Grid container spacing={4} justifyContent="center">
          <Grid item xs={12} md={8}>
            <Box
              sx={{
                p: 2,
                borderRadius: 2,
                boxShadow: "0px 0px 15px 2px rgba(255, 0, 127, 0.6)",
              }}
            >
              <Typography variant="h5" sx={{ color: "#FF007F", mb: 2 }}>
                Business Improvements with Hive Labs AI Solutions
              </Typography>
              <Bar data={chartData} options={chartOptions} />
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box
              sx={{
                p: 2,
                borderRadius: 2,
                boxShadow: "0px 0px 15px 2px rgba(255, 0, 127, 0.6)",
              }}
            >
              <Typography variant="h5" sx={{ color: "#FF007F", mb: 2 }}>
                AI Solutions We Provide
              </Typography>
              <Doughnut data={doughnutData} />
            </Box>
          </Grid>
        </Grid>

        {/* Expanded Case Study Narrative */}
        <Typography
          variant="h4"
          sx={{ color: "#FF007F", fontWeight: "bold", mt: 6, mb: 2 }}
        >
          Hive Labs AI in Action: Key Successes
        </Typography>
        <Typography variant="body1" paragraph>
          Hive Labs has implemented AI solutions across diverse industries to
          help businesses scale and improve efficiency:
        </Typography>

        {/* Use Cases from the Case Study */}
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} md={4}>
            <Box sx={{ p: 2, borderRadius: 2, backgroundColor: "#2c2c2c" }}>
              <Typography
                variant="h5"
                sx={{ color: "#FF007F", fontWeight: "bold", mb: 1 }}
              >
                Legal Services
              </Typography>
              <Typography variant="body2">
                We developed AI-powered chatbots, automated legal document
                generation, and integrated an AI-driven legal research
                assistant. These solutions reduced operational costs by 40% and
                increased case handling capacity.
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} md={4}>
            <Box sx={{ p: 2, borderRadius: 2, backgroundColor: "#2c2c2c" }}>
              <Typography
                variant="h5"
                sx={{ color: "#FF007F", fontWeight: "bold", mb: 1 }}
              >
                E-Commerce (Retail)
              </Typography>
              <Typography variant="body2">
                We implemented AI chatbots for customer support and
                TensorFlow-based models for demand forecasting, resulting in
                optimized inventory management and a 15% increase in operational
                efficiency.
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} md={4}>
            <Box sx={{ p: 2, borderRadius: 2, backgroundColor: "#2c2c2c" }}>
              <Typography
                variant="h5"
                sx={{ color: "#FF007F", fontWeight: "bold", mb: 1 }}
              >
                Healthcare
              </Typography>
              <Typography variant="body2">
                Using AI-powered diagnostics and virtual assistants, we helped
                healthcare providers improve patient outcomes and streamline
                appointment scheduling, achieving a 20% reduction in
                administrative costs.
              </Typography>
            </Box>
          </Grid>
        </Grid>

        {/* Benefits of Hive Labs AI Solutions */}
        <Typography
          variant="h6"
          sx={{ color: "#E0FFFD", fontWeight: "bold", mt: 4 }}
        >
          Why Businesses Choose Hive Labs:
        </Typography>
        <Typography variant="body1" paragraph>
          • Tailored AI solutions designed to address specific business needs.
          <br />
          • Expertise in large language models (LLMs) and augmented retrieval
          systems for fast, accurate data handling.
          <br />
          • Proven track record in automating workflows and improving
          decision-making across industries.
          <br />• Scalable AI models that grow with your business and unlock new
          opportunities.
        </Typography>

        {/* Call to Action */}
        <Typography
          variant="h5"
          sx={{ color: "#FF007F", fontWeight: "bold", mt: 4 }}
        >
          Transform Your Business with AI Today!
        </Typography>
        <Typography variant="body1" paragraph>
          Let Hive Labs show you how AI can revolutionize your operations.
          Whether you’re looking to enhance customer engagement, streamline
          workflows, or integrate cutting-edge AI technologies, we’re ready to
          help.
        </Typography>
      </Container>
    </Box>
  );
};

export default CaseStudySection;
