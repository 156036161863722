import React from 'react';
import {
  Container,
  Grid,
  Box,
  Typography,
  Paper,
  Divider,
  Button,
} from '@mui/material';
import { motion } from 'framer-motion';
import Footer from './Footer';

// Updated Pricing Plans with additional web hosting service
const pricingPlans = [
  {
    title: 'Starter Package',
    price: '$2,500 / month',
    features: [
      'AI Consultation',
      'Basic AI Integration (e.g., Chatbots)',
      'Web Design Support',
      'Monthly Support',
    ],
    backgroundColor: 'linear-gradient(135deg, #FF007F 30%, #FF6F91 100%)',
  },
  {
    title: 'Growth Package',
    price: '$5,500 / month',
    features: [
      'Advanced AI Solutions',
      'Data Analytics & Automation',
      'Full Website Design',
      'Dedicated Support',
      'Training Sessions',
    ],
    backgroundColor: 'linear-gradient(135deg, #00FFC6 30%, #00FFEE 100%)',
  },
  {
    title: 'Enterprise Package',
    price: '$12,000 / month',
    features: [
      'Full-Scale AI Strategy',
      'Custom AI Solutions Development',
      'Full Web Design + E-Commerce',
      '24/7 Support with Account Manager',
      'Advanced Training & Maintenance',
    ],
    backgroundColor: 'linear-gradient(135deg, #00BFFF 30%, #00DFFF 100%)',
  },
];

// Motion Variants for animations
const cardVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0 },
};

// Reusable Pricing Card Component
const PricingCard = ({ plan }) => (
  <motion.div variants={cardVariants}>
    <Paper
      elevation={5}
      sx={{
        padding: 4,
        borderRadius: 3,
        background: plan.backgroundColor,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        transition: 'transform 0.3s',
        boxShadow: '0 10px 30px rgba(0, 0, 0, 0.2)',
        '&:hover': {
          transform: 'translateY(-10px)',
          boxShadow: '0 12px 35px rgba(0, 0, 0, 0.3)',
        },
      }}
      aria-label={plan.title}
    >
      <Typography
        variant="h5"
        sx={{ color: '#ffffff', fontWeight: 'bold', mb: 2 }}
      >
        {plan.title}
      </Typography>
      <Typography variant="h6" sx={{ color: '#ffffff', mb: 2 }}>
        {plan.price}
      </Typography>
      <Divider sx={{ my: 2, backgroundColor: 'rgba(255, 255, 255, 0.6)' }} />
      <Box component="ul" sx={{ listStyle: 'none', padding: 0 }}>
        {plan.features.map((feature, index) => (
          <Typography
            key={index}
            variant="body1"
            sx={{ color: '#ffffff', marginBottom: '0.5rem' }}
          >
            {feature}
          </Typography>
        ))}
      </Box>
      <Button
        variant="contained"
        sx={{
          backgroundColor: '#ffffff',
          color: '#FF007F',
          marginTop: '1.5rem',
          '&:hover': { backgroundColor: '#FF007F', color: '#ffffff' },
        }}
      >
        Choose Plan
      </Button>
    </Paper>
  </motion.div>
);

const PricingSection = () => {
  return (
    <Box
      id="pricing"
      sx={{
        position: 'absolute',
        zIndex: 2,
        width: '100%',
        height: '100%',
        backgroundColor: '#1a1a1a', // Matte black background
        color: '#E0FFFD', // Neon cyan text for contrast
        overflowY: 'scroll',
        top: 0,
        pointerEvents: 'all',
        paddingTop: '64px',
        backdropFilter: 'blur(10px)', // Adding some blur
      }}
    >
      <Container>
        {/* Introduction Section */}
        <motion.div
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <Grid container justifyContent="center">
            <Grid item xs={12} md={8}>
              <Box mb={5} textAlign="center">
                <Typography
                  variant="h3"
                  gutterBottom
                  sx={{
                    color: '#FF007F',
                    fontWeight: 'bold',
                    letterSpacing: '0.1rem',
                  }}
                >
                  Flexible AI Solutions for Every Business
                </Typography>
                <Typography variant="h6" color="#E0FFFD">
                  At Hive Labs, we offer customized pricing models that cater to
                  the needs of businesses at all stages. Whether you’re a
                  startup or an enterprise, our plans provide maximum value,
                  scalability, and continuous support.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </motion.div>

        {/* Pricing Models Overview */}
        <Box mt={8}>
          <Typography
            variant="h4"
            align="center"
            gutterBottom
            sx={{
              color: '#FF007F',
              fontWeight: 'bold',
              letterSpacing: '0.1rem',
            }}
          >
            Pricing Models Overview
          </Typography>
          <Grid container spacing={4} justifyContent="center">
            {[
              {
                title: 'Retainer Model',
                description: [
                  'Fixed monthly fee',
                  'Dedicated services',
                  'Priority support',
                ],
                backgroundColor: '#FF007F',
              },
              {
                title: 'Subscription Model',
                description: [
                  'Regular billing cycles',
                  'Tiered service levels',
                  'Scalable options',
                ],
                backgroundColor: '#00FFC6',
              },
              {
                title: 'Project-Based Pricing',
                description: [
                  'One-time fees',
                  'Defined deliverables',
                  'Flexible timelines',
                ],
                backgroundColor: '#00BFFF',
              },
            ].map((model, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Paper
                  sx={{
                    padding: 4,
                    borderRadius: 3,
                    backgroundColor: model.backgroundColor,
                    height: '100%',
                    color: '#fff',
                    boxShadow: '0 8px 20px rgba(0,0,0,0.2)',
                    textAlign: 'center',
                  }}
                >
                  <Typography
                    variant="h5"
                    sx={{
                      color: '#1a1a1a',
                      fontWeight: 'bold',
                      letterSpacing: '0.05rem',
                    }}
                  >
                    {model.title}
                  </Typography>
                  <Box component="ul" sx={{ listStyle: 'none', padding: 0 }}>
                    {model.description.map((item, idx) => (
                      <Typography key={idx} variant="body1">
                        {item}
                      </Typography>
                    ))}
                  </Box>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Box>

        {/* Subscription Packages */}
        <Box mt={8}>
          <Typography
            variant="h4"
            align="center"
            gutterBottom
            sx={{
              color: '#FF007F',
              fontWeight: 'bold',
              letterSpacing: '0.1rem',
            }}
          >
            Tiered Subscription Packages
          </Typography>
          <motion.div
            initial="hidden"
            animate="visible"
            transition={{ staggerChildren: 0.2 }}
          >
            <Grid container spacing={4} justifyContent="center">
              {pricingPlans.map((plan, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <PricingCard plan={plan} />
                </Grid>
              ))}
            </Grid>
          </motion.div>
        </Box>

        {/* Web Design Packages */}
        <Box mt={8}>
          <Typography
            variant="h4"
            align="center"
            gutterBottom
            sx={{
              color: '#FF007F',
              fontWeight: 'bold',
              letterSpacing: '0.1rem',
            }}
          >
            Web Design Packages
          </Typography>
          <Grid container spacing={4} justifyContent="center">
            {[
              {
                title: 'Basic Web Design',
                price: 'Starting at $3,000',
                features: [
                  'Basic Layout & Design',
                  'SEO Optimization',
                ],
                backgroundColor: '#FF007F',
              },
              {
                title: 'Advanced Web Design',
                price: 'Starting at $5,000',
                features: [
                  'Custom Layout & Design',
                  'SEO, Analytics, E-Commerce Integration',
                ],
                backgroundColor: '#00FFC6',
              },
            ].map((packageData, index) => (
              <Grid item xs={12} sm={6} key={index}>
                <Paper
                  sx={{
                    padding: 4,
                    borderRadius: 3,
                    backgroundColor: packageData.backgroundColor,
                    height: '100%',
                    color: '#fff',
                    boxShadow: '0 8px 20px rgba(0,0,0,0.2)',
                    textAlign: 'center',
                  }}
                >
                  <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                    {packageData.title}
                  </Typography>
                  <Typography variant="body1" sx={{ marginTop: '1rem' }}>
                    {packageData.price}
                  </Typography>
                  <Box component="ul" sx={{ listStyle: 'none', padding: 0 }}>
                    {packageData.features.map((feature, idx) => (
                      <Typography key={idx} variant="body1">
                        {feature}
                      </Typography>
                    ))}
                  </Box>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Box>

        {/* Additional Services */}
        <Box mt={8}>
          <Typography
            variant="h4"
            align="center"
            gutterBottom
            sx={{
              color: '#FF007F',
              fontWeight: 'bold',
              letterSpacing: '0.1rem',
            }}
          >
            Additional Services and Add-Ons
          </Typography>
          <Grid container spacing={4} justifyContent="center">
            <Grid item xs={12} sm={6}>
              <Paper
                sx={{
                  padding: 4,
                  borderRadius: 3,
                  backgroundColor: '#FF007F',
                  height: '100%',
                  color: '#fff',
                  boxShadow: '0 8px 20px rgba(0,0,0,0.2)',
                  textAlign: 'center',
                }}
              >
                <Box component="ul" sx={{ listStyle: 'none', padding: 0 }}>
                  <Typography variant="body1">
                    AI Model Training: $2,000/month
                  </Typography>
                  <Typography variant="body1">
                    Data Integration: $1,500/month
                  </Typography>
                  <Typography variant="body1">
                    Web Maintenance: $1,200/month
                  </Typography>
                  <Typography variant="body1">
                    Web Hosting & Monitoring: $100/month
                  </Typography>
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper
                sx={{
                  padding: 4,
                  borderRadius: 3,
                  backgroundColor: '#00FFC6',
                  height: '100%',
                  color: '#fff',
                  boxShadow: '0 8px 20px rgba(0,0,0,0.2)',
                  textAlign: 'center',
                }}
              >
                <Box component="ul" sx={{ listStyle: 'none', padding: 0 }}>
                  <Typography variant="body1">
                    Reporting Dashboards: $1,200/month
                  </Typography>
                  <Typography variant="body1">
                    Priority Support: Additional $500/month
                  </Typography>
                  <Typography variant="body1">
                    Advanced Analytics: $1,800/month
                  </Typography>
                  <Typography variant="body1">
                    SEO Monitoring: $1,000/month
                  </Typography>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Box>

        {/* Billing Flexibility */}
        <Box mt={8}>
          <Typography
            variant="h4"
            align="center"
            gutterBottom
            sx={{
              color: '#FF007F',
              fontWeight: 'bold',
              letterSpacing: '0.1rem',
            }}
          >
            Billing Flexibility
          </Typography>
          <Typography variant="body1" color="#E0FFFD" align="center" mt={3}>
            We offer multiple billing options to suit your financial planning:
          </Typography>
          <Box component="ul" sx={{ textAlign: 'center', listStyle: 'none', padding: 0 }}>
            <Typography variant="body1" sx={{ color: '#00FFC6' }}>
              Monthly Billing: Ideal for short-term commitments.
            </Typography>
            <Typography variant="body1" sx={{ color: '#00FFC6' }}>
              Quarterly Billing: Receive discounts for longer-term engagements.
            </Typography>
            <Typography variant="body1" sx={{ color: '#00FFC6' }}>
              Annual Billing: Enjoy the highest discounts for long-term partnerships.
            </Typography>
          </Box>
        </Box>

        <Divider sx={{ mt: 8, mb: 5, backgroundColor: '#FF007F' }} />
      </Container>

      {/* Footer with matching background */}
      <Footer />
    </Box>
  );
};

export default PricingSection;
