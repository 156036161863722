import React from 'react';
import { Container, Grid, Typography, Box } from '@mui/material';
import { Bar, Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, ArcElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, ArcElement, Title, Tooltip, Legend);

const FullStackDevSection = () => {
  // Data for bar chart (Framework Popularity)
  const chartData = {
    labels: ['React', 'Python', 'PyTorch', 'TensorFlow', 'Node.js', 'Pinecone'],
    datasets: [
      {
        label: 'Framework Adoption',
        data: [90, 80, 70, 75, 85, 65],
        backgroundColor: '#FF007F', // Neon pink color
      },
    ],
  };

  // Data for doughnut chart (Full-Stack Technologies Impact)
  const doughnutData = {
    labels: ['Frontend (React)', 'Backend (Node.js)', 'AI/ML (TensorFlow/PyTorch)', 'Database (Pinecone)'],
    datasets: [
      {
        label: 'Tech Stack Composition',
        data: [40, 30, 20, 10],
        backgroundColor: ['#FF007F', '#00FFC6', '#E0FFFD', '#FF3399'], // Neon pink, cyan, green, light pink
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
        labels: {
          color: '#E0FFFD', // Neon cyan text for legend
        },
      },
      title: {
        display: true,
        text: 'Impact of Full-Stack Development at Hive Labs',
        color: '#E0FFFD',
        font: {
          size: 20,
        },
      },
    },
    scales: {
      x: {
        ticks: {
          color: '#E0FFFD', // Neon cyan for x-axis
        },
      },
      y: {
        ticks: {
          color: '#E0FFFD',
        },
        grid: {
          color: 'rgba(224, 255, 253, 0.2)', // Light cyan grid
        },
      },
    },
  };

  return (
    <Box
      sx={{
        backgroundColor: '#1a1a1a',
        py: 8,
        px: 3,
        color: '#E0FFFD', // Neon cyan text
        textAlign: 'center',
      }}
    >
      <Container>
        <Typography variant="h3" sx={{ color: '#FF007F', fontWeight: 'bold', mb: 3 }}>
          Full-Stack Development at Hive Labs
        </Typography>
        <Typography variant="body1" paragraph sx={{ mb: 5 }}>
          At Hive Labs, we specialize in building scalable full-stack solutions using the most powerful frameworks, including React for frontend, Python and Node.js for backend, and PyTorch and TensorFlow for AI/ML models. Our approach enables us to deliver rapid and robust products tailored to your needs.
        </Typography>

        {/* Charts Section */}
        <Grid container spacing={4} justifyContent="center">
          <Grid item xs={12} md={8}>
            <Box sx={{ p: 2, borderRadius: 2, boxShadow: '0px 0px 15px 2px rgba(255, 0, 127, 0.6)' }}>
              <Typography variant="h5" sx={{ color: '#FF007F', mb: 2 }}>
                Framework Adoption in Our Projects
              </Typography>
              <Bar data={chartData} options={chartOptions} />
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box sx={{ p: 2, borderRadius: 2, boxShadow: '0px 0px 15px 2px rgba(255, 0, 127, 0.6)' }}>
              <Typography variant="h5" sx={{ color: '#FF007F', mb: 2 }}>
                Full-Stack Technologies We Use
              </Typography>
              <Doughnut data={doughnutData} />
            </Box>
          </Grid>
        </Grid>

        {/* Expanded Development Case Study */}
        <Typography variant="h4" sx={{ color: '#FF007F', fontWeight: 'bold', mt: 6, mb: 2 }}>
          Full-Stack Development Success Stories
        </Typography>
        <Typography variant="body1" paragraph>
          Hive Labs has successfully built full-stack applications across various industries, rapidly deploying powerful solutions using modern frameworks:
        </Typography>

        {/* Full-Stack Use Cases */}
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} md={4}>
            <Box sx={{ p: 2, borderRadius: 2, backgroundColor: '#2c2c2c' }}>
              <Typography variant="h5" sx={{ color: '#FF007F', fontWeight: 'bold', mb: 1 }}>
                Fintech 
              </Typography>
              <Typography variant="body2">
                We built an advanced financial application using React, Node.js, and Pinecone to enable seamless data transactions and integrations with third-party APIs, reducing processing time by 30%.
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} md={4}>
            <Box sx={{ p: 2, borderRadius: 2, backgroundColor: '#2c2c2c' }}>
              <Typography variant="h5" sx={{ color: '#FF007F', fontWeight: 'bold', mb: 1 }}>
                Education
              </Typography>
              <Typography variant="body2">
                We developed a dynamic learning management system using React and Python, leveraging PyTorch for personalized AI-driven learning experiences, increasing engagement by 25%.
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} md={4}>
            <Box sx={{ p: 2, borderRadius: 2, backgroundColor: '#2c2c2c' }}>
              <Typography variant="h5" sx={{ color: '#FF007F', fontWeight: 'bold', mb: 1 }}>
                Healthcare
              </Typography>
              <Typography variant="body2">
                Using React, Node.js, and TensorFlow, we developed a platform for predictive health diagnostics and appointment management, improving service efficiency by 40%.
              </Typography>
            </Box>
          </Grid>
        </Grid>

        {/* Benefits of Full-Stack Development */}
        <Typography variant="h6" sx={{ color: '#E0FFFD', fontWeight: 'bold', mt: 4 }}>
          Why Businesses Choose Hive Labs for Full-Stack Development:
        </Typography>
        <Typography variant="body1" paragraph>
          • Comprehensive full-stack solutions using modern frameworks for both frontend and backend.<br />
          • Expertise in AI/ML frameworks such as PyTorch and TensorFlow to add intelligence to your applications.<br />
          • Rapid product development and scalable architecture to support your growing business needs.<br />
          • Seamless integration of databases like Pinecone for advanced data handling and retrieval.
        </Typography>

        {/* Call to Action */}
        <Typography variant="h5" sx={{ color: '#FF007F', fontWeight: 'bold', mt: 4 }}>
          Build Your Full-Stack Product with Hive Labs Today!
        </Typography>
        <Typography variant="body1" paragraph>
          Whether you need a custom-built application or want to integrate AI technologies, Hive Labs is your trusted partner for full-stack development. Let’s bring your vision to life.
        </Typography>
      </Container>
    </Box>
  );
};

export default FullStackDevSection;
