import React, { useState } from 'react';
import {
  Container,
  Grid,
  Box,
  Typography,
  Paper,
  TextField,
  Button,
  Divider,
  Checkbox,
  FormControlLabel,
  Snackbar,
  Alert,
  IconButton,
} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import SignaturePad from 'react-signature-canvas';
import useSubmitContract from '../hooks/useSubmitContract'; // Import the hook
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';


const ContractPage = () => {
  // State for signature pad and snackbar
  const [signaturePad, setSignaturePad] = useState(null);
  const [successMessageOpen, setSuccessMessageOpen] = useState(false); // For Snackbar
  const { submitContract } = useSubmitContract(); // Use the hook

  const navigate = useNavigate();

  // Function to handle closing of Snackbar
  const handleCloseSnackbar = () => {
    setSuccessMessageOpen(false);
  };

  // Form validation using Formik and Yup
  const formik = useFormik({
    initialValues: {
      fullName: '',
      email: '',
      company: '',
      agreeToTerms: false,
    },
    validationSchema: Yup.object({
      fullName: Yup.string().required('Full Name is required'),
      email: Yup.string().email('Invalid email format').required('Email is required'),
      company: Yup.string().required('Company Name is required'),
      agreeToTerms: Yup.boolean().oneOf([true], 'You must accept the terms and conditions'),
    }),
    onSubmit: async (values, { resetForm }) => {
      // Handle form submission
      if (signaturePad.isEmpty()) {
        alert('Please provide your signature.');
      } else {
        const signatureData = signaturePad.toDataURL();
        try {
          await submitContract(values, signatureData); // Call the hook's submitContract function
          resetForm(); // Clear form
          signaturePad.clear(); // Clear signature
          setSuccessMessageOpen(true); // Show success message
          navigate('/thank-you');
        } catch (error) {
          console.error('Error submitting contract:', error);
        }
      }
    },
  });

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <Box
      sx={{
        width: '100%',
        background: 'linear-gradient(135deg, #1a1a1a 30%, #333333 100%)',
        paddingY: { xs: '3rem', md: '5rem' },
        color: '#E0FFFD',
      }}
    >
      <Container maxWidth="md">
        {/* Contract Section */}
        <Paper
          sx={{
            padding: { xs: 3, md: 5 },
            borderRadius: 3,
            boxShadow: '0 10px 30px rgba(0, 0, 0, 0.2)',
            backgroundColor: '#222', // Dark background to match the theme
            color: '#E0FFFD', // Light text
            marginBottom: '2rem',
          }}
        >
     <Typography
  variant="h4"
  align="center"
  sx={{ fontWeight: 'bold', mb: 3, color: '#FF007F' }}
>
  Software Development and AI Services Agreement
</Typography>
<Divider sx={{ mb: 3, backgroundColor: '#FF007F' }} />
<Typography variant="body1" sx={{ mb: 3 }}>
  This Software Development and AI Services Agreement ("Agreement") is entered into by and between Hive Labs ("Provider") and the subscribing entity or individual ("Client"). By subscribing, the Client agrees to the following terms and conditions:
</Typography>

<Typography variant="body1" sx={{ mb: 2 }}>
  1. **Services Provided**: Hive Labs agrees to provide full-stack web development, machine learning (ML) and artificial intelligence (AI) development, and blockchain development services on the Internet Computer Protocol, as outlined on the pricing page or agreed in writing. This includes:
  <ul style={{ paddingLeft: '20px' }}>
    <li>Consulting, design, and development for AI and software solutions</li>
    <li>Building custom applications, smart contracts, and decentralized apps (dApps) on blockchain platforms</li>
    <li>Testing, deployment, and ongoing support for all solutions</li>
  </ul>
</Typography>

<Typography variant="body1" sx={{ mb: 2 }}>
  2. **Ownership of Deliverables**: The Client owns all intellectual property, code, and assets developed specifically for them by Hive Labs unless otherwise agreed in writing. This includes full ownership of custom AI models, web applications, and blockchain-based products built under this Agreement. Hive Labs retains the right to reuse non-client-specific components, methodologies, or tools for other projects.
</Typography>

<Typography variant="body1" sx={{ mb: 2 }}>
  3. **Fees and Payment**: The Client agrees to pay all applicable fees according to the subscription or project terms selected. Payments are due based on the agreed billing cycle (monthly, quarterly, or annually) or milestone completions for project-based work. Hive Labs reserves the right to pause or terminate services if payment is not received within seven (7) days of the due date.
</Typography>

<Typography variant="body1" sx={{ mb: 2 }}>
  4. **Confidentiality**: Both Hive Labs and the Client agree to keep confidential any proprietary or sensitive information disclosed during the term of this Agreement, including technical data, business plans, and user data. The confidentiality obligation remains in effect for three (3) years following the termination of this Agreement.
</Typography>

<Typography variant="body1" sx={{ mb: 2 }}>
  5. **Warranties and Representations**:
  <ul style={{ paddingLeft: '20px' }}>
    <li><b>Hive Labs Warranties:</b> Hive Labs warrants that the services provided will be of a professional standard consistent with industry best practices. Deliverables will be free from malware or harmful code, and will meet the agreed specifications.</li>
    <li><b>Client Warranties:</b> The Client warrants that it owns or has the necessary rights to any materials, content, or data provided to Hive Labs for use in the project. The Client is responsible for ensuring that these materials do not infringe the rights of any third party.</li>
  </ul>
</Typography>

<Typography variant="body1" sx={{ mb: 2 }}>
  6. **Intellectual Property Rights**:
  <ul style={{ paddingLeft: '20px' }}>
    <li><b>Client IP:</b> All intellectual property created specifically for the Client, including AI models, web code, blockchain applications, and other software products, is the exclusive property of the Client upon full payment of fees.</li>
    <li><b>Hive Labs IP:</b> Hive Labs retains ownership of any proprietary technologies, algorithms, development tools, or reusable components that were not specifically developed for the Client. These may be used for other clients without limitation.</li>
  </ul>
</Typography>

<Typography variant="body1" sx={{ mb: 2 }}>
  7. **Limitation of Liability**: 
  Hive Labs' liability for any claims arising out of this Agreement is limited to the total fees paid by the Client in the twelve (12) months preceding the claim. Hive Labs is not liable for indirect, incidental, or consequential damages, such as lost profits or data, even if the possibility of such damages was known.
</Typography>

<Typography variant="body1" sx={{ mb: 2 }}>
  8. **Force Majeure**: 
  Hive Labs shall not be liable for delays or failure to perform its obligations under this Agreement due to events beyond its control, including but not limited to natural disasters, labor strikes, cyber-attacks, or disruptions in internet or blockchain networks.
</Typography>

<Typography variant="body1" sx={{ mb: 2 }}>
  9. **Data Privacy and Security**: 
  Hive Labs will implement appropriate security measures to protect the Client's data. The Client acknowledges that their data may be processed and stored on third-party cloud platforms or blockchain protocols. Hive Labs will only use platforms that comply with industry-standard data protection and privacy laws.
</Typography>

<Typography variant="body1" sx={{ mb: 2 }}>
  10. **Indemnification**: 
  The Client agrees to indemnify and hold Hive Labs harmless from any claims, damages, liabilities, and costs arising from third-party claims related to the Client’s content, use of the deliverables, or any violation of law by the Client.
</Typography>

<Typography variant="body1" sx={{ mb: 2 }}>
  11. **Termination**: 
  This Agreement may be terminated by either party with thirty (30) days' written notice. If either party materially breaches this Agreement, including non-payment by the Client or misuse of the deliverables, the non-breaching party may terminate the Agreement immediately. Upon termination, Hive Labs will transfer ownership of the completed deliverables to the Client, provided all fees have been paid.
</Typography>

<Typography variant="body1" sx={{ mb: 2 }}>
  12. **Dispute Resolution and Governing Law**: 
  This Agreement is governed by the laws of Florida, without regard to conflict of law principles. Any disputes arising under this Agreement shall be resolved through binding arbitration in Miami Florida.
</Typography>

<Typography variant="body1" sx={{ mb: 2 }}>
  13. **Blockchain and Smart Contracts**: 
  For blockchain-related development on the Internet Computer Protocol or other platforms, Hive Labs will deploy smart contracts, decentralized applications (dApps), or blockchain-based systems. The Client acknowledges that Hive Labs is not responsible for any inherent risks associated with blockchain technologies, such as security vulnerabilities, protocol changes, or fluctuations in gas fees. 
</Typography>

<Typography variant="body1" sx={{ mb: 2 }}>
  14. **Modifications to Agreement**: 
  Hive Labs reserves the right to modify this Agreement with thirty (30) days' notice. Continued use of services after the notice period constitutes the Client’s acceptance of the revised terms.
</Typography>

<Typography variant="body1" sx={{ mb: 3 }}>
  By signing this agreement and submitting your details, you agree to the terms and conditions outlined in this contract.
</Typography>

        </Paper>

        {/* Form Section */}
        <Paper
          sx={{
            padding: { xs: 3, md: 5 },
            borderRadius: 3,
            boxShadow: '0 10px 30px rgba(0, 0, 0, 0.2)',
            backgroundColor: '#444', // Match your dark theme
            color: '#fff',
          }}
        >
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography
                  variant="h5"
                  sx={{ mb: 2, color: '#FF007F', fontWeight: 'bold' }}
                >
                  Enter Your Information
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  id="fullName"
                  name="fullName"
                  label="Full Name"
                  value={formik.values.fullName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.fullName && Boolean(formik.errors.fullName)}
                  helperText={formik.touched.fullName && formik.errors.fullName}
                  InputLabelProps={{ style: { color: '#fff' } }}
                  sx={{ input: { color: '#fff' } }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  id="email"
                  name="email"
                  label="Email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                  InputLabelProps={{ style: { color: '#fff' } }}
                  sx={{ input: { color: '#fff' } }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="company"
                  name="company"
                  label="Company Name"
                  value={formik.values.company}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.company && Boolean(formik.errors.company)}
                  helperText={formik.touched.company && formik.errors.company}
                  InputLabelProps={{ style: { color: '#fff' } }}
                  sx={{ input: { color: '#fff' } }}
                />
              </Grid>

              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      id="agreeToTerms"
                      name="agreeToTerms"
                      color="primary"
                      checked={formik.values.agreeToTerms}
                      onChange={formik.handleChange}
                      sx={{ color: '#FF007F' }}
                    />
                  }
                  label="I agree to the terms and conditions"
                  sx={{ color: '#fff' }}
                />
                {formik.touched.agreeToTerms && formik.errors.agreeToTerms && (
                  <Typography variant="body2" color="error">
                    {formik.errors.agreeToTerms}
                  </Typography>
                )}
              </Grid>

              {/* Signature Section */}
              <Grid item xs={12}>
                <Typography
                  variant="h6"
                  sx={{ mb: 2, color: '#FF007F', fontWeight: 'bold' }}
                >
                  Signature
                </Typography>
                <SignaturePad
                  ref={(ref) => setSignaturePad(ref)}
                  canvasProps={{
                    width: 500,
                    height: 200,
                    style: { border: '1px solid #FF007F', backgroundColor: '#fff' },
                  }}
                />
                <Button
                  sx={{ mt: 2, color: '#FF007F', borderColor: '#FF007F' }}
                  variant="outlined"
                  onClick={() => signaturePad.clear()}
                >
                  Clear Signature
                </Button>
              </Grid>

              {/* Submit Button */}
              <Grid item xs={12}>
                <Button
                  fullWidth
                  variant="contained"
                  sx={{
                    mt: 3,
                    backgroundColor: '#FF007F',
                    color: '#fff',
                    '&:hover': {
                      backgroundColor: '#FF4E8A',
                    },
                  }}
                  type="submit"
                >
                  Submit 
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Container>
         {/* Snackbar for success message */}
         <Snackbar
        open={successMessageOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
          Contract submitted successfully!
        </Alert>
      </Snackbar>
       {/* Close Button */}
       <Box textAlign="center" mt={4}>
          <IconButton
            onClick={handleBack}
            sx={{
              color: '#FF007F',
              backgroundColor: '#1a1a1a',
              border: '2px solid #FF007F',
              borderRadius: '50%',
              padding: '10px',
              transition: 'transform 0.3s, box-shadow 0.3s',
              '&:hover': {
                transform: 'scale(1.1)',
                boxShadow: '0 4px 20px rgba(255, 0, 127, 0.6)',
              },
            }}
            aria-label="Close"
          >
            <CloseIcon fontSize="large" />
          </IconButton>
        </Box>

    </Box>
  );
};

export default ContractPage;
