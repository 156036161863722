import React from 'react';
import { Helmet } from 'react-helmet';

const MetaTags = () => (
  <Helmet>
    <title>Hive AI - Advancing the Frontiers of AI Research</title>
    <meta charSet="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no" />
    <meta name="author" content="Hive AI" />
    <meta name="description" content="Our strategy revolves around harnessing cutting-edge machine learning techniques to uncover insights that push the boundaries of human intuition." />
    <meta name="keywords" content="Hive AI, AI research, machine learning, breakthroughs, security, efficiency, data-driven innovation, scientific discoveries, AI systems" />
    <meta name="robots" content="index, follow" />
    <link rel="icon" type="image/png" href="/images/favicons/favicon.png" sizes="32x32" />
    {/* Add other meta tags here */}
  </Helmet>
);

export default MetaTags;
