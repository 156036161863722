// hooks/useSubmitContract.js


import { ref, set } from "firebase/database";
import { db } from "../firebase";

const useSubmitContract = () => {
  const submitContract = async (values, signatureData) => {
    try {
      // Generate a valid path using a timestamp
      const contractRef = ref(db, 'contracts/' + new Date().getTime());
      
      // Data to store in Firebase
      const contractData = {
        fullName: values.fullName,
        email: values.email,
        company: values.company,
        agreeToTerms: values.agreeToTerms,
        signature: signatureData,
        submittedAt: new Date().toISOString(),
      };

      await set(contractRef, contractData);
      alert('Contract submitted successfully!');
    } catch (error) {
      console.error('Error submitting contract:', error);
      alert('Error submitting contract. Please try again.');
    }
  };

  return { submitContract };
};

export default useSubmitContract;
